import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const AppSearch = Loadable(lazy(() => import('./AppSearch')));

const SearchRoutes = [
  {
    path: '/search/casnum',
    element: <AppSearch />,
  },
];

export default SearchRoutes;
